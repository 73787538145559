<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="openDialog()"
          >
            <v-icon> mdi-tag-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Attach Task Groups</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Attach Task Groups
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <SearchBar
              :key="dialog"
              searchLabel="Search by task group name..."
              @search="onSearch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-data-table
                class="available-task-group-label-list"
                v-model="selected"
                :headers="headers"
                :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
                :items="items"
                :server-items-length="itemsCount"
                :options.sync="options"
                must-sort
                show-select
                checkbox-color="msaBlue"
                @update:options="getItems()"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="close()">cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="confirm()"
          :disabled="selected.length == 0"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'AttachTaskGroups',
  components: {
    SearchBar,
  },
  data() {
    return {
      dialog: false,
      items: [],
      itemsCount: 0,
      search: '',
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
      ],
      selected: [],
    };
  },
  methods: {
    getItems() {
      const params = {
        search: this.search,
        options: this.options,
        loaderText: 'Loading...',
        labelId: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('get-available-task-groups-for-label?format=json', params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },
    onSearch(event) {
      this.search = event;
      this.options.page = 1;
      this.getItems();
    },
    openDialog() {
      this.resetFields();
      this.getItems();
    },
    resetFields() {
      this.selected = [];
      this.search = '';
      this.options.sortDesc = [false];
      this.options.page = this.$constants.PAGINATION.DEFAULT_PAGE;
      this.options.itemsPerPage = this.$constants.PAGINATION.DEFAULT_PERPAGE;
    },
    close() {
      this.dialog = false;
    },
    confirm() {
      const params = {
        taskGroupTemplateIds: this.selected.map((d) => d.id),
        loaderText: 'Loading...',
        labelId: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('attach-task-groups-to-label?format=json', params)
        .then(() => {
          this.$emit('refresh');
          this.close();
        })
        .catch((error) => error);
    },
  },
};
</script>

<style>
.available-task-group-label-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
